<template>
  <div v-if="user && complaints" class="mb-15">
    <v-card v-if="complaints" elevation="3" class="pa-5">
      <v-card-text>
        <v-row class="mb-2">
          <v-col class="pa-5" cols="">
            <span
              class="font-weight-bold display-1 text-uppercase deep-purple--text"
            >
              filing of complaint
            </span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <OpenAIPrompt :prompt="form.statement_of_facts" />
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <br />
        <br />
        <v-form ref="form">
          <v-row>
            <v-col cols="8">
              <v-row v-for="(input, a) in form.complainant" :key="a">
                <v-col>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        prepend-icon="mdi-account-outline"
                        class="body-2 rounded-xl"
                        :rules="[rules.required]"
                        hide-details=""
                        dense
                        label="Name of Complainant"
                        placeholder="Name of Complainant"
                        v-model="input.complainant_name"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        type="number"
                        prepend-icon=""
                        class="body-2 rounded-xl"
                        :rules="[rules.required]"
                        dense
                        label="Age"
                        placeholder="Age"
                        hide-details=""
                        v-model="input.complainant_age"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-select
                        prepend-icon="mdi-account-multiple-outline"
                        class="body-2"
                        :rules="[rules.required]"
                        :items="civil_status"
                        dense
                        label="Civil Status"
                        placeholder="Civil Status"
                        hide-details=""
                        v-model="input.complainant_civil_status"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col>
                      <v-text-field
                        prepend-icon="mdi-map-marker-outline"
                        class="body-2"
                        :rules="[rules.required]"
                        dense
                        label="Permanent Address"
                        placeholder="Permanent Address"
                        hide-details=""
                        v-model="input.complainant_address"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-icon
                  @click="remove_complainant(a)"
                  color="deep-purple accent-4"
                  v-show="a || (!a && form.complainant.length > 1)"
                  >mdi-minus-circle</v-icon
                >
              </v-row>

              <v-row>
                <v-col>
                  <v-icon @click="add_complainant" color="deep-purple accent-4"
                    >mdi-plus-circle</v-icon
                  >
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="2" offset="2">
                  <h4>VS</h4>
                </v-col>
              </v-row>
              <v-row v-for="(input, name, k) in form.respondent" v-bind:key="k">
                <!-- <v-row> -->
                <v-col>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        prepend-icon="mdi-account-outline"
                        class="body-2"
                        :rules="[rules.required]"
                        hide-details=""
                        dense
                        label="Name of Respondent"
                        placeholder="Name of Respondent"
                        v-model="input.respondent_name"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        type="Number"
                        class="body-2"
                        :rules="[rules.required]"
                        dense
                        label="Age"
                        placeholder="Age"
                        hide-details=""
                        v-model="input.respondent_age"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-select
                        prepend-icon="mdi-account-multiple-outline"
                        class="body-2"
                        :rules="[rules.required]"
                        :items="civil_status"
                        dense
                        label="Civil Status"
                        placeholder="Civil Status"
                        hide-details=""
                        v-model="input.respondent_civil_status"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col>
                      <v-text-field
                        prepend-icon="mdi-map-marker-outline"
                        class="body-2"
                        :rules="[rules.required]"
                        dense
                        label="Permanent Address"
                        placeholder="Permanent Address"
                        hide-details=""
                        v-model="input.respondent_address"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-icon
                  @click="remove_respondent(k)"
                  color="deep-purple accent-4"
                  v-show="k || (!k && form.respondent.length > 1)"
                  >mdi-minus-circle</v-icon
                >
              </v-row>
              <v-row>
                <v-col>
                  <v-icon @click="add_respondent" color="deep-purple accent-4"
                    >mdi-plus-circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="4">
              <!-- <v-row> </v-row> -->

              <v-row>
                <v-col offset="2">
                  <v-select
                    :rules="[rules.required]"
                    :items="type_of_case"
                    class="mb-4 body-2"
                    dense
                    label="Case Type"
                    placeholder="Type of Case"
                    hide-details=""
                    v-model="form.case_type"
                  >
                  </v-select>

                  <v-select
                    :rules="[rules.required]"
                    class="select body-2 mb-4"
                    :items="
                      form.case_type == 'Criminal Case'
                        ? criminal_case
                        : civil_case
                    "
                    dense
                    label="Case Title"
                    placeholder="Case Title"
                    hide-details=""
                    v-model="form.case_title"
                  >
                  </v-select>
                  <v-text-field
                    v-if="form.case_title == 'Others'"
                    class="body-2"
                    :rules="[rules.required]"
                    dense
                    label="Specify"
                    hide-details=""
                    v-model="form.others"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="2" offset="2">
                  <h4 style="visibility: hidden">VS</h4>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col offset="2">
                  <v-text-field
                    label="Date of Filing"
                    dense
                    class="mb-0 body-2"
                    v-model="form.date_of_filing"
                    readonly
                  ></v-text-field>
                  <p class="font-weight-bold">Schedule of First Hearing</p>
                  <div class="d-flex mt-n2">
                    <p>Date:</p>
                    <input
                      class="ml-2"
                      type="date"
                      :rules="[rules.required]"
                      v-model="form.date"
                      @input="concatenate_date()"
                    />
                  </div>

                  <div class="d-flex mt-n2">
                    <p>Time:</p>
                    <input
                      class="ml-2"
                      type="time"
                      :rules="[rules.required]"
                      v-model="form.time"
                      @input="concatenate_date()"
                    />
                  </div>
                  <!-- <v-datetime-picker
                    class="body-2 mt-0 mb-0"
                    :rules="[rules.required]"
                    label="Date and Time of Hearing"
                  ></v-datetime-picker> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                prepend-icon="mdi-chat-processing-outline"
                class="body-2"
                :rules="[rules.required]"
                label="Statements of Facts"
                placeholder="Statements of Facts"
                hide-details=""
                v-model="form.statement_of_facts"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                prepend-icon="mdi-hand-coin-outline"
                class="body-2"
                :rules="[rules.required]"
                label="Reliefs Sought"
                placeholder="Reliefs Sought"
                hide-details=""
                v-model="form.reliefs_sought"
              >
              </v-textarea>
            </v-col>
          </v-row>

          <v-row class="d-flex justify-right">
            <v-col>
              <v-btn
                color="deep-purple accent-4"
                class="rounded-xl"
                dark
                @click="submit"
              >
                <v-icon></v-icon>
                Submit
              </v-btn>
              <v-btn
                color="yellow"
                class="rounded-xl black--text ml-2"
                dark
                @click="reset"
              >
                <v-icon></v-icon>
                Reset
              </v-btn></v-col
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="submit"
        @cancel-event="close_confirm"
        :color="'deep-purple lighten-4'"
      />
    </div>

    <progress-linear :dialog="progress_dialog" />
  </div>
</template>
<script>
import cases from "@/json/cases.json";
import { mapActions, mapGetters } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
import moment from "moment";
import OpenAIPrompt from "../openAIPrompt.vue";

export default {
  components: {
    ConfirmNotification: () => import("@/components/ConfirmNotification.vue"),
    ProgressLinear,
    OpenAIPrompt,
  },
  data() {
    return {
      imported_cases: cases,
      rules: {
        required: (value) => !!value || "Required.",
      },
      case_number_value: "",
      case_number1: null,
      // case_number2: "",
      dialog1: false,
      show_alert: false,
      progress_dialog: false,
      alert_message: "Are you sure you want to add this complaint?",
      date_of_filing_dialog: false,
      date_of_first_hearing_dialog: false,
      civil_status: ["Single", "Married", "Widow/Widower"],
      case_selected: [],
      form: {
        // exclusive_case_no: "",
        date: "",
        time: "",
        case_type: "",
        case_title: "",
        others: "",
        date_of_filing: moment().format("MMM D, YYYY"),
        date_of_first_hearing: "",
        time_of_first_hearing: "",
        statement_of_facts: "",
        reliefs_sought: "",
        complainant: [
          {
            complainant_name: "",
            complainant_age: "",
            complainant_civil_status: "",
            complainant_address: "",
          },
        ],
        respondent: [
          {
            respondent_name: "",
            respondent_age: "",
            respondent_civil_status: "",
            respondent_address: "",
          },
        ],
        table_route_history: ["Mediation"],
      },
      type_of_case: ["Criminal Case", "Civil Case"],
      cases: cases,
      criminal_case: cases[0].criminal_case.sort(),
      civil_case: cases[0].civil_case.sort(),
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      complaints: "complaint/complaints",
    }),
  },
  methods: {
    ...mapActions({
      add_pre_file_complaint: "complaint/add_pre_file_complaint",
      add_complaint: "complaint/add_complaint",
      set_notification: "notification/set_notification",
      add_mediation: "mediation/add_mediation",
      get_complaints: "complaint/get_complaints",
    }),
    add_complainant() {
      this.form.complainant.push({
        complainant_name: "",
        complainant_age: "",
        complainant_civil_status: "",
        complainant_address: "",
      });
    },
    add_respondent() {
      this.form.respondent.push({
        respondent_name: "",
        respondent_age: "",
        respondent_civil_status: "",
        respondent_address: "",
      });
    },
    remove_complainant(index) {
      this.form.complainant.splice(index, 1);
    },
    remove_respondent(index) {
      this.form.respondent.splice(index, 1);
    },
    concatenate_date() {
      let date_time = this.form.date + " " + this.form.time;
      console.log(date_time);
      this.form.date_of_first_hearing = date_time;
      this.is_valid_date(this.form, 5);
    },
    confirm_payment() {
      this.dialog1 = true;
    },
    is_valid_date(form, days) {
      this.format_datetime(form.date_of_first_hearing);
      var date_of_filing = moment(form.date_of_filing);
      var date_today = moment();
      var threshold_date = date_today.add(days, "days");
      var date_of_first_hearing = moment(form.date_of_first_hearing);

      // if above
      if (date_of_first_hearing.isAfter(threshold_date, "day")) {
        this.set_notification({
          message:
            "The date of hearing should not exceed five (5) days from the date of filing the complaint.",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        return false;
      }
      // if below
      else if (date_of_first_hearing.isBefore(date_of_filing, "day")) {
        this.set_notification({
          message:
            "The date of hearing should not be below the date of filing of the complaint.",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        return false;
      }
      return true;
    },
    is_form_validated() {
      if (this.form.date_of_first_hearing == "")
        this.set_notification({
          message: "All fields are required",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
      else if (this.$refs.form.validate()) return true;
      else
        this.set_notification({
          message: "All fields are required",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
    },

    format_datetime(date) {
      var formated_date = moment(date).format("Y-M-D HH:mm");
      return formated_date;
    },

    async submit() {
      if (!this.is_form_validated()) return;
      if (!this.is_valid_date(this.form, 6)) return;
      this.form.mediator = [this.user.name_of_punong_barangay];
      this.form.date_of_first_hearing = this.format_datetime(
        this.form.date_of_first_hearing
      );
      this.add_pre_file_complaint(this.form);
      this.$router.push("/questions/" + this.form.exclusive_case_no);
      this.progress_dialog = false;
    },

    close_confirm() {
      this.show_alert = false;
      this.submit_loading = false;
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  created() {},
};
</script>
<style scoped>
.v-select {
  max-width: 272px;
}

.rounded-card {
  border-radius: 20px;
}
</style>
