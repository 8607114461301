<template>
  <div class="d-flex">
    <p class="mt-3">Not sure what Case Type/Title is it?</p>
    <v-btn
      color="white"
      class="black--text mt-5 ml-2"
      small
      @click="sendPrompt"
    >
      Ask us!
    </v-btn>
    <!-- LAZY LOADDED -->
    <MessageAlertModal
      :response="messageResponse"
      v-if="enableMessage"
      @close="enableMessage = false"
    />
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["prompt"],
  components: {
    MessageAlertModal: () =>
      import("@/components/Alerts/messageAlertsModal.vue"),
  },
  data() {
    return {
      enableMessage: false,
      messageResponse: "",
      message: "", // To hold user input
      criminal_case: [
        "Unlawful use of means of publication and unlawful utterances",
        "Alarms and scandals",
        "Using false certificates",
        "Using fictitious names and concealing true names",
        "Illegal use of uniforms and insignias",
        "Physical injuries inflicted in a tumultuous affray",
        "Giving assistance to consummated suicide",
        "Less serious physical injuries",
        "Slight physical injuries and maltreatment",
        "Unlawful arrest",
        "Inducing a minor to abandon his/her home",
        "Abandonment of a person in danger and abandonment of one’s own victim",
        "Abandoning a minor (a child under seven years old)",
        "Abandonment of a minor by persons entrusted with his/her custody;",
        "Indifference of parents",
        "Qualified trespass to dwelling (without the use of violence and intimidation)",
        "Other forms of trespass",
        "Light threats",
        "Other light threats",
        "Grave coercion",
        "Light coercion",
        "Other similar coercions (compulsory purchase of merchandise and payment of wages by means of tokens)",
        "Formation, maintenance and prohibition of combination of capital or labor through violence or threats",
        "Discovering secrets through seizure and correspondence",
        "Revealing secrets with abuse of authority",
        "Theft (if the value of the property stolen does not exceed Php 50)",
        "Qualified theft (if the amount does not exceed Php 500)",
        "Occupation of real property or usurpation of real rights in property",
        "Altering boundaries or landmarks",
        "Swindling or estafa (if the amount does not exceed Php 200)",
        "Other forms of swindling",
        "Swindling a minor",
        "Slander",
        "Other deceits",
        "Removal, sale or pledge of mortgaged property",
        "Special cases of malicious mischief (if the value of the damaged property does not exceed Php 1,000)",
        "Other mischiefs (if the value of the damaged property does not exceed Php1,000)",
        "Simple seduction",
        "Acts of lasciviousness with the consent of the offended party",
        "Threatening to publish and offer to prevent such publication for compensation",
        "Prohibiting publication of acts referred to in the course of official proceedings",
        "Incriminating innocent persons",
        "Intriguing against honor",
        "Issuing checks without sufficient funds (Bouncing Checks Law or BP 22);",
        "Fencing of stolen properties (if the property involved is not more than Php 50 under PD 1612).",
      ],
      civil_case: [
        "Cancellation of Contract",
        "Collection of Sum of Money",
        "Damages",
        "Specific Performance of Obligation",
        "Others",
      ],
    };
  },
  methods: {
    async sendPrompt() {
      let format =
        "This is the case statement please respond in Cebuano and make it very short,";
      let message =
        format +
        " " +
        this.prompt +
        ". What case type(Criminal or Civil) and Case Title that is strictly based on this list:" +
        this.criminal_case +
        this.civil_case +
        "?";
      if (
        !window.confirm(
          "The prompt will be based on your statement of facts. Do you wish to proceed?"
        )
      )
        return;

      const apiKey =
        "sk-nuQUeuOU5vNMEJ2wk4IfSd2DMA5muL6me-Ut6r7ta7T3BlbkFJXbp981TOSsfhhYYsoTl-YCyqpE_-FyH1u97lxEJKkA";

      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "chatgpt-4o-latest", // Use "gpt-3.5-turbo" if you're using that
            messages: [
              {
                role: "user",
                content: message, // The user's input
              },
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Display the response from OpenAI
        // alert();
        this.messageResponse = response.data.choices[0].message.content;
        this.enableMessage = true;
      } catch (error) {
        this.messageResponse = error.response.data.error.message;
        this.enableMessage = true;
      }
    },
  },
};
</script>

<style></style>
