import { render, staticRenderFns } from "./messageAlertsModal.vue?vue&type=template&id=7713001e&scoped=true&"
import script from "./messageAlertsModal.vue?vue&type=script&lang=js&"
export * from "./messageAlertsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7713001e",
  null
  
)

export default component.exports